body {
  margin: 0;
  font-family: -apple-system, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}


/* Styles for time picker panel (child of body element) */
.rc-time-picker-panel {
  z-index: 9999 !important;
}

.rc-time-picker-panel-input-wrap {
  padding: 0 !important;
}

.rc-time-picker-panel-input {
  padding: 8px 16px !important;
  font-size: 16px;
  font-family: 'Unify Sans', sans-serif;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #292928 !important;
  color: #000000;
  text-transform: uppercase;
}

.rc-time-picker-panel-input-invalid {
  border: 2px solid #C20F1E !important;
}

.rc-time-picker-panel-select {
  font-size: 16px !important;
  font-family: 'Unify Sans', sans-serif;
  min-height: 210px !important;
  text-transform: uppercase;
}

.rc-time-picker-panel-select li {
  height: 40px !important;
  display: flex;
  align-items: center;
}

li.rc-time-picker-panel-select-option-selected {
  background: #F5F8FF !important;
}

.rc-time-picker-panel-select::-webkit-scrollbar{
  display: none;
}
.maintenance {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.maintenance-background-bar {
  height: 50px;
  background-color: #000000;
  top: 12px;
}

.maintenance-logo {
  margin-top: 13px;
  margin-left: 8px;
}

@media print {
  .summary-container {
    width: 80% !important;
  }
}

.custom-popover {
  z-index: 0 !important;
}

.custom-popover > .MuiBackdrop-root {
  display: none;
}