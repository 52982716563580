
@font-face {
font-family: 'Unify Sans';
src: url('./fonts/UnifySans_W_Rg.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/UnifySans_W_Rg.woff') format('woff'), /* Pretty Modern Browsers */
    url('./fonts/UnifySans_Rg.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
font-family: 'Unify Sans Bold';
src: url('./fonts/UnifySans_W_Bd.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/UnifySans_W_Bd.woff') format('woff'), /* Pretty Modern Browsers */
    url('./fonts/UnifySans_Bd.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
font-family: 'Unify Sans SemiBold';
src: url('./fonts/UnifySans_W_SBd.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/UnifySans_W_SBd.woff') format('woff'), /* Pretty Modern Browsers */
    url('./fonts/UnifySans_SBd.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
font-family: 'Unify Sans Italic';
src: url('./fonts/UnifySans_W_It.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/UnifySans_W_It.woff') format('woff'), /* Pretty Modern Browsers */
    url('./fonts/UnifySans_It.ttf')  format('truetype'); /* Safari, Android, iOS */
}
